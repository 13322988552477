import React, { Fragment, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { Footer, Header } from '@digital-retail/falabella-ui-cross-components';
import Router from 'next/router';
import {
  isStandalone,
  isSodimacStandalone,
  isTottusStandalone,
  CARTLINE_QUANTITIES_COOKIE_NAME
} from '@digital-retail/store-manager';
import { layoutStyle } from './Layout.style';
import Loader from '../../components/Loader/Loader';
import _ from '../../utils/LodashImports';
import { navigateWithUrl, redirect } from '../../utils/RouteHandler';
import WebStorage from '../../utils/WebStorage';
import constants from '../../config/constants';
import UserContext from '../../utils/UserContext';
import WithUrl from '../../utils/WithUrl';
import CookieStorage from '../../utils/CookieStorage';

const LayoutContainer = ({ children, appCtx }) => {
  const layoutCss = layoutStyle(appCtx.tenant);
  const [cartCount, setCartCount] = useState('');
  const { refreshUserData } = useContext(UserContext.Context);

  useEffect(() => {
    if (appCtx.USER_DATA_COOKIE) {
      const userDetails = appCtx.USER_DATA_COOKIE.split('*');
      const newCartCount = userDetails[1];
      setCartCount(newCartCount);

      if (newCartCount === '0') {
        CookieStorage.removeCookie({ name: CARTLINE_QUANTITIES_COOKIE_NAME });
      }
    }
  }, [appCtx.USER_DATA_COOKIE]);

  const currentLocation = _.get(appCtx, 'zoneName', '');

  const atgBaseUrl = _.get(
    appCtx,
    'siteConfig.endpoints.ATG_AUTH_BASEURL',
    'https://www.falabella.com'
  );
  const catalystBaseUrl = _.get(
    appCtx,
    'header.entry.header_configs[0].links.catalystbaseurl',
    'https://www.falabella.com'
  );

  const transversalBannerConfig = JSON.parse(
    _.get(appCtx, 'siteConfig.toggles.transversalBannerConfig', '{}')
  );

  const { deviceType } = appCtx;
  const isMobile = deviceType === constants.DEVICE_MOBILE;

  const disableGeofinderModalForMobile =
    appCtx?.siteConfig?.toggles?.disableGeofinderModalForMobile && isMobile;

  const handleRouteChange = (termo, searchInSis) => {
    const page = isStandalone({ store: appCtx.store })
      ? 'SA_SEARCH_PAGE'
      : 'SEARCH_PAGE';
    const urlParams = {
      custom: {
        searchTerm: termo
      }
    };

    if ((appCtx.store && searchInSis) || !appCtx.store) {
      if (Router.router.route !== '/search') {
        const url = WithUrl(page, appCtx.regionCode, urlParams, appCtx.store);
        redirect(url);
      } else {
        navigateWithUrl({
          rootUrl: isStandalone({ store: appCtx.store })
            ? '/buscar'
            : '/search',
          site: appCtx.tenant,
          url: `Ntt=${termo}`,
          store: appCtx.store,
          regionCode: appCtx.regionCode,
          onRouteChange: () => {
            WebStorage.setItem({
              name: constants.SESSION_STORAGE_NEW_PAGE,
              shouldPersist: false,
              value: 'true'
            });
            appCtx.setBusy(true);
          }
        });
      }
    } else {
      const url = WithUrl(page, appCtx.regionCode, urlParams, appCtx.store);
      redirect(url);
    }
  };

  const onUserLogin = () => {
    refreshUserData();
  };
  const getFooterData = () => {
    const rawFooterData = _.get(appCtx, 'footer');
    if (!rawFooterData) return rawFooterData;
    return {
      ...rawFooterData,
      entry: {
        ...rawFooterData?.entry,
        bottom_banner: {
          // eslint-disable-next-line camelcase
          ...rawFooterData?.entry?.bottom_banner,
          enabled: false
        }
      }
    };
  };

  const isTottusSA = isTottusStandalone(appCtx.store);
  const isSodimacSA = isSodimacStandalone(appCtx.store);

  return (
    <Fragment>
      <Head>
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-Black.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-BlackItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-Bold.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-BoldItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-Hairline.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-HairlineItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-Italic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-Light.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-LightItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/fonts/Lato-Regular.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/icons/fonts/sodimac.ttf?fu3jcb"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/product/static/icons/fonts/sodimac.woff?fu3jcb"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Head>
      <Header
        currentLocation={currentLocation}
        data={{
          header: {
            ..._.get(appCtx, 'header'),
            transversalBannerHtml: _.get(appCtx, 'transversalBanner')
          },
          sisNavigationMenu: _.get(appCtx, 'sisNavigationMenu'),
          taxonomy: _.get(appCtx, 'taxonomy')
        }}
        isGeoLocationEnabled={_.get(
          appCtx,
          'siteConfig.toggles.isGeoLocationEnabled',
          false
        )}
        isTypeAheadEnabled={_.get(
          appCtx,
          'siteConfig.toggles.isTypeAheadEnabled',
          false
        )}
        isATGLoginEnabled={_.get(
          appCtx,
          'siteConfig.toggles.isATGLoginEnabled',
          false
        )}
        isDefaultComuna={_.get(appCtx, 'isDefaultComuna', false)}
        selectedZone={_.get(appCtx, 'comuna.data', undefined)}
        onUserLogout={appCtx.logoutUser}
        onUserLogin={onUserLogin}
        isOrangeHeader={appCtx.isOrangeHeader}
        isSISLevel2Header={appCtx.isSISLevel2Header}
        enableAnalyticsInfoCall
        isZoneModalEnabled
        userCartCount={cartCount}
        parentRouter={Router}
        handleRouteChange={handleRouteChange}
        catalystBaseUrl={catalystBaseUrl}
        atgBaseUrl={atgBaseUrl}
        store={appCtx.store}
        handleSoftRefresh={appCtx.reloadOnZoneChange}
        isPopOverVisible={!disableGeofinderModalForMobile}
        isWebPushNotificationEnabled={_.get(
          appCtx,
          'siteConfig.toggles.isWebPushNotificationEnabled',
          false
        )}
        transversalBannerConfig={transversalBannerConfig}
        locationPathname={appCtx.url}
        isGoogleTagManagerEnabled
        isSodimacStandAloneSite={isSodimacSA}
        isTottusStandAloneSite={isTottusSA}
      />
      <div className="main">{children}</div>
      {appCtx.busy > 0 && <Loader />}
      <Footer data={getFooterData()} />
      <style jsx>{layoutCss}</style>
    </Fragment>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node,
  appCtx: PropTypes.object.isRequired
};

LayoutContainer.defaultProps = {
  children: null
};

export default LayoutContainer;
