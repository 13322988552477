import { isStandalone, getUrlParts } from '@digital-retail/store-manager';
import { isWindow } from './deviceChecker';
import httpService from './httpService';
import apiConfig from '../config/api/apiConfig';
import Logger from './Logger';
import defaultSiteConfig from '../config/siteConfig.default';
import stores from '../config/storeMap';
import constants from '../config/constants';
import _ from './LodashImports';
import { geoFallback } from '../data/fallbacks/geo';

const fetchConfigFromApi = async (
  config,
  appCtx,
  url,
  headers,
  opt = {},
  fallback = {}
) => {
  if (config.ENABLE_MEM_CACHE) {
    const valueFromCache = config.inMemCache.get(url);
    if (!valueFromCache) {
      const valueFromApi = await httpService(appCtx).get(url, {
        headers,
        ...opt
      });
      config.inMemCache.set(url, valueFromApi);
      return valueFromApi;
    }
    return valueFromCache;
  }
  return httpService(appCtx).get(
    url,
    {
      headers,
      ...opt
    },
    'text',
    fallback
  );
};
const isZoneDataInvalid = (zoneData = {}) => {
  return (
    !zoneData.politicalId ||
    !zoneData.priceGroupId ||
    !Array.isArray(zoneData.zones) ||
    !(Array.isArray(zoneData.zones) && zoneData.zones.length > 0)
  );
};
const isvalidZone = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return false;
  }
};
const appServices = (
  publicRuntimeConfig,
  serverRuntimeConfig,
  appCtx,
  query
) => ({
  siteConfig: {
    init: async () => {
      const { getAccessToken } = serverRuntimeConfig;
      const { regionCode, store } = appCtx;
      const tokenResponse = await getAccessToken(regionCode);
      const storePath = store ? `/${store}` : '/marketplace';
      const siteConfigUrl = `${
        publicRuntimeConfig.SITE_CONFIG_URL
      }${storePath}?country=${regionCode}&parseJson=true&merge=true`;
      return fetchConfigFromApi(serverRuntimeConfig, appCtx, siteConfigUrl, {
        Authorization: tokenResponse
      });
    },
    resolve: ({ data, error }) => {
      if (error) {
        Logger.error(`Using fallback site-config => ${JSON.stringify(error)}`);
        return defaultSiteConfig;
      }
      return data;
    }
  },
  // to be removed
  zonesInfo: {
    init: () => {
      const { regionCode } = appCtx;
      // Changed this call to new API
      const getLocationsUrl = apiConfig.getLocationsUrl({
        base: isWindow()
          ? publicRuntimeConfig.CUSTOMER_LOCATION_HOST
          : publicRuntimeConfig.CLUSTER_CUSTOMER_LOCATION_HOST,
        siteId: regionCode
      });
      return fetchConfigFromApi(
        serverRuntimeConfig,
        appCtx,
        getLocationsUrl,
        {},
        {},
        { data: geoFallback.zones.v1[regionCode] }
      );
    },
    resolve: ({ data, error }) => {
      if (error) {
        Logger.error(`Using default zones => ${JSON.stringify(error)}`);
        return {
          zoneID: constants.ZONE.DEFAULT_ZONE_ID,
          zoneName: constants.ZONE.DEFAULT_ZONE_NAME
        };
      }
      const { defaultZone, availableZones, data: zones } = data;
      const { name: zoneName } = _.find(zones, ['id', defaultZone]);
      return { zoneID: defaultZone, zoneName, zones, availableZones };
    }
  },
  transversalBanner: {
    init: async () => {
      const obj = {
        base: isWindow()
          ? publicRuntimeConfig.CONTENT_API_HOST
          : publicRuntimeConfig.CLUSTER_CONTENT_API_HOST,
        regionCode: appCtx.regionCode
      };
      const transversalBannerUrl = apiConfig.getTransversalBannerUrl({
        ...obj,
        version: appCtx.isRebrandingEnabled ? 'v3' : 'v2'
      });
      return fetchConfigFromApi(
        serverRuntimeConfig,
        appCtx,
        transversalBannerUrl,
        {},
        { asText: true }
      );
    },
    resolve: ({ data, error }) => {
      if (error) {
        Logger.error(
          `Unable to get transversal banner => ${JSON.stringify(error)}`
        );
        return '';
      }
      return data;
    }
  },
  header: {
    init: async () => {
      const { store, isRebrandingEnabled } = appCtx;
      const site = isStandalone({ store }) ? store : 'fa_com';
      const obj = {
        base: isWindow()
          ? publicRuntimeConfig.CONTENT_API_HOST
          : publicRuntimeConfig.CLUSTER_CONTENT_API_HOST,
        regionCode: appCtx.regionCode,
        site
      };
      const headerURL = apiConfig.getHeaderUrl({
        ...obj,
        version: isRebrandingEnabled ? 'v3' : 'v2'
      });
      return fetchConfigFromApi(serverRuntimeConfig, appCtx, headerURL, {});
    },
    resolve: ({ data, error }) => {
      if (error) {
        Logger.error(`Unable to get header => ${JSON.stringify(error)}`);
        return {};
      }
      return data;
    }
  },
  sisNavigationMenu: {
    init: async () => {
      const { regionCode, store } = appCtx;
      const site = isStandalone({ store }) ? store : 'fa_com';
      const obj = {
        base: isWindow()
          ? publicRuntimeConfig.CONTENT_API_HOST
          : publicRuntimeConfig.CLUSTER_CONTENT_API_HOST,
        regionCode
      };
      if (query.store && stores[query.store.toLowerCase()]) {
        const sisNavigationMenuURL = apiConfig.getSISNavigationMenuURL({
          ...obj,
          version: 'v2',
          store: query.subdomain || query.store,
          site
        });
        return fetchConfigFromApi(
          serverRuntimeConfig,
          appCtx,
          sisNavigationMenuURL,
          {}
        );
      }
      return {
        data: {}
      };
    },
    resolve: ({ data, error }) => {
      if (error) {
        Logger.error(
          `Unable to get sisNavigationMenu => ${JSON.stringify(error)}`
        );
        return {};
      }
      return data;
    }
  },
  footer: {
    init: async () => {
      const { regionCode, store } = appCtx;
      const site = isStandalone({ store }) ? store : 'fa_com';
      const obj = {
        base: isWindow()
          ? publicRuntimeConfig.CONTENT_API_HOST
          : publicRuntimeConfig.CLUSTER_CONTENT_API_HOST,
        regionCode,
        site
      };
      const footerURL = apiConfig.getFooterUrl({
        ...obj,
        version: 'v3'
      });
      return fetchConfigFromApi(serverRuntimeConfig, appCtx, footerURL, {});
    },
    resolve: ({ data, error }) => {
      if (error) {
        Logger.error(`Unable to get footer => ${JSON.stringify(error)}`);
        return '';
      }
      return data;
    }
  },
  taxonomy: {
    init: async () => {
      const obj = {
        base: isWindow()
          ? publicRuntimeConfig.CONTENT_API_HOST
          : publicRuntimeConfig.CLUSTER_CONTENT_API_HOST,
        regionCode: appCtx.regionCode,
        version:
          appCtx.regionCode === 'cl' || appCtx.isRebrandingEnabled ? 'v2' : 'v1'
      };
      const taxonomyURL = apiConfig.taxonomyURL(obj);
      return fetchConfigFromApi(serverRuntimeConfig, appCtx, taxonomyURL, {});
    },
    resolve: ({ data, error }) => {
      if (error) {
        Logger.error(`Unable to get taxonomy => ${JSON.stringify(error)}`);
        return '';
      }
      return data;
    }
  },
  geo: {
    init: () => {
      // Changed this call to new API
      const getLocationsUrl = apiConfig.getGeoUrl({
        base: isWindow()
          ? publicRuntimeConfig.CUSTOMER_LOCATION_HOST
          : publicRuntimeConfig.CLUSTER_CUSTOMER_LOCATION_HOST,
        siteId: appCtx.regionCode
      });

      return fetchConfigFromApi(serverRuntimeConfig, appCtx, getLocationsUrl);
    },
    resolve: ({ data, error }) => {
      if (error) {
        Logger.error(`Using default zones => ${JSON.stringify(error)}`);
        return {
          defaultZone: constants.ANDES_DEF_ZONE
        };
      }
      const { default: defaultZone } = data;
      return { defaultZone };
    }
  },
  comuna: {
    init: () => {
      const { store, regionCode, pid, latLong } = appCtx;
      let baseUrl = isWindow()
        ? publicRuntimeConfig.CUSTOMER_LOCATION_HOST
        : publicRuntimeConfig.CLUSTER_CUSTOMER_LOCATION_HOST;
      if (isStandalone({ store })) {
        const environment = publicRuntimeConfig.ENVIRONMENT || 'production';
        const { origin } = getUrlParts({ store, environment, regionCode });
        baseUrl = `${origin}${publicRuntimeConfig.CL_ENDPOINT}`;
      }
      const getComunaUrl = apiConfig.getDistrictInfo({
        base: baseUrl,
        siteId: regionCode,
        politicalId: pid,
        latLong
      });
      return fetchConfigFromApi(serverRuntimeConfig, appCtx, getComunaUrl);
    },
    resolve: async ({ data, error }) => {
      const zoneData = _.get(data, 'data', {});
      if (isZoneDataInvalid(zoneData)) {
        const { regionCode } = appCtx;
        Logger.error(
          `Using default zones from redis=> ${JSON.stringify(error)}`
        );
        const { getDefaultZone } = serverRuntimeConfig;
        try {
          const defaultZone = await getDefaultZone(regionCode);
          const defaultZoneParsed = isvalidZone(defaultZone);
          if (isZoneDataInvalid(defaultZoneParsed)) {
            Logger.error(`Default zone fetch from redis failed`);
            return {
              data: constants.DEF_ZONE[regionCode][process.env.ENVIRONMENT],
              default: true,
              fromCatch: true
            };
          }
          return { data: defaultZoneParsed, default: true, redis: true };
        } catch (err) {
          Logger.error(
            `Using default zones from constants => ${JSON.stringify(err)}`
          );
          return {
            data: constants.DEF_ZONE[regionCode][process.env.ENVIRONMENT],
            default: true,
            fromCatch: true
          };
        }
      }
      return data;
    }
  }
});
// ! Must be called only on server
const fetchApplicationData = (
  publicRuntimeConfig,
  serverRuntimeConfig,
  appCtx,
  query
) => ({
  fetch: async (services) => {
    const { tracingHeaders } = appCtx;
    const fetcher = appServices(
      publicRuntimeConfig,
      serverRuntimeConfig,
      appCtx,
      query
    );
    const promises = services
      .filter((s) => fetcher[s])
      .map((service) => {
        return fetcher[service].init();
      });
    let data;
    try {
      data = await Promise.all(promises);
    } catch (e) {
      Logger.error(`Error Running Promise => ${e}`, tracingHeaders);
    }
    // eslint-disable-next-line consistent-return
    return services.map((service, i) => {
      return fetcher[service].resolve(data[i]);
    });
  }
});
export { fetchApplicationData, isZoneDataInvalid };
